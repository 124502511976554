import React from 'react';
 import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
 
import Navbar from './Navbar';
import Acceuil from './Acceuil';
import Formation from './Formation';
import Etudes from './Etudes';
import Epls from './Epls';
import Fichiers from './Fichiers';
import Circulaires from './Circulaires'

function App() {
  return (
    <Router>
      <div>
        <Navbar />
 
        <Routes>
          <Route path="/" exact Component={Acceuil} />
          <Route path="/Formation" Component={Formation} />
          <Route path="/Etudes" Component={Etudes} />
          <Route path="/Epls" Component={Epls} />
          <Route path="/Fichiers" Component={Fichiers} />
          <Route path="/Circulaires" Component={Circulaires} />

        </Routes>
        </div>
    </Router>
  );
}

export default App;
