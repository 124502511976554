import React from 'react';
import Modalo from './Modalo';

function Formation() {
  return (
<Modalo/>
  );
}

export default Formation;
