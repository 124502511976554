import React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from './Navbar';
 
function Etudes() {
  return (
    <div> 

    <h1> Etudes</h1>
    </div>
  );
}

export default Etudes;
