import React from 'react';
import Cardi from './Cardi';
import './styles.css';
 
function Epls() {
  return (
    <h1> epls </h1>

  );
}

export default Epls;
