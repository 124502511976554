import React from 'react';

import Cardepls from './Cardepls';
import Cardformation from './Cardformation';
import Cardetudes from './Cardetudes';
import Cardfichiers from './Cardfichiers';
import Cardirculaires from './Cardirculaires';
 import  './script.js';
 import Modalo from './Modalo.js';



function Acceuil() {
  return (

    <div >
      <p className='p'> 
      Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. Generate your own Lorem Ipsum with a dictionary of over 200 Latin words and a set of mock banners.
      </p>
       <p className='p'> 
      Learn about the history, usage and variations of Lorem Ipsum, the dummy text of the printing and typesetting industry. Generate your own Lorem Ipsum with a dictionary of over 200 Latin words and a set of mock banners.
      </p>
<div className='acceuil'>



      <Cardformation/>
      <Cardetudes/>
      <Cardepls/>
      <Cardfichiers/>
      <Cardirculaires/>
 

     </div>
     </div>
  );
}

export default Acceuil;
