import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import './Modalo.css'; // Import the CSS file
import formationss from './formationss.png'
import pdfy from './pdfy.pdf'
 
import Pdfviewer from './Pdfviewer';



function Modalo() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <h1>Page with Button and Modal</h1>
      <button onClick={openModal}>Open Modal</button>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
             
            <iframe src={pdfy}/>

             </div>
        </div>
      )}
    </div>
  );
}

export default Modalo;
